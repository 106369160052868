import React, { Suspense } from 'react'

import Loading from 'app/common/loading/Loading'

// Lazy load App compontents to prevent both versions of
// Bootstrap styles loading at the same time.
const LegacyApp = React.lazy(() => import('app/AppWithStore'))
const LatestApp = React.lazy(() => import('v2/App'))

interface AppProps {
  version: 1 | 2
}

const App = ({ version }: AppProps) => {
  return (
    <Suspense fallback={<Loading />}>
      {version === 2 ? <LatestApp /> : <LegacyApp />}
    </Suspense>
  )
}

export default App