import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './datadogRumInit'
import ReactDOM from 'react-dom'

import config from 'app/config'
import App from './App'
import * as serviceWorker from './serviceWorker'

// We use the window.location.pathname to detect which version to load.
// This allows us to use different versions of Bootstrap and React Router
// in different UI versions.
const version = window.location.pathname.startsWith(config.versionPathname.v2)
  ? 2
  : 1

ReactDOM.render(<App version={version} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
